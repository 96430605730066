import Swiper, { Navigation, Pagination } from 'swiper';
import GLightbox from 'glightbox';

Swiper.use([Navigation, Pagination]);

const initSliders = () => {
  const items = document.querySelectorAll<HTMLDivElement>('.rooms__item');

  items.forEach((item) => {
    const slider = item.querySelector<HTMLDivElement>('.swiper');
    const prev = item.querySelector<HTMLButtonElement>('.rooms__prev');
    const next = item.querySelector<HTMLButtonElement>('.rooms__next');
    const pagination = item.querySelector<HTMLDivElement>('.rooms__pagination');

    new Swiper(slider!, {
      loop: true,
      // spaceBetween: 12,
      // slidesPerView: 1,
      // centeredSlides: true,

      navigation: {
        prevEl: prev,
        nextEl: next,
      },

      pagination: {
        el: pagination,
        clickable: true,
      },
    });
  });
};

const initZoom = () => {
  const sliders = document.querySelectorAll<HTMLDivElement>('.rooms__slider');
  sliders.forEach((slider) => {
    const slides = slider.querySelectorAll<HTMLDivElement>('.swiper-slide');

    const items = Array.from(slides)
      .filter((item) => !item.classList.contains('swiper-slide-duplicate'))
      .map((item) => ({ href: item.firstElementChild?.getAttribute('href') }));

    const glightbox = GLightbox({
      elements: items,
      loop: true,
    });

    slides.forEach((slide) => {
      slide.firstElementChild?.addEventListener('click', (event) => {
        event.preventDefault();
        glightbox.openAt(slide.dataset.swiperSlideIndex);
      });
    });

    // const id = slider.dataset.id;
    // GLightbox({
    //   selector: `.rooms__slider[data-id="${id}"] .swiper-slide:not(.swiper-slide-duplicate) .rooms__slide`,
    //   loop: true,
    // });
  });
};

const initVideo = () => {
  GLightbox({
    selector: '.rooms__video a',
    // autoplayVideos: true,
    // loop: true,
    // width: '100vw',
    // height: '100vh',
    // videosWidth: '100vw',
    plyr: {
      config: {
        ratio: '2:3',
        // ratio: '1:1.5',
        hideControls: true,
      },
    },
  });
};

const initMore = () => {
  const rooms = document.querySelector('.rooms');
  const button = document.querySelector('.rooms__more');
  button?.addEventListener('click', () => rooms?.classList.add('rooms_opened'));
};

const rooms = {
  init: () => {
    initSliders();
    initZoom();
    initVideo();
    initMore();
  },
};

export default rooms;
