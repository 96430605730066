import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
// import * as serviceWorker from './serviceWorker';

import header from './scripts/header';
import gallery from './scripts/gallery';
import rooms from './scripts/rooms';
import nutrition from './scripts/nutrition';
import cafe from './scripts/cafe';
import gorgippia from './scripts/gorgippia';
import beach from './scripts/beach';
import instagram from './scripts/instagram';
import reviews from './scripts/reviews';
import hotels from './scripts/hotels';
import vacancy from './scripts/vacancy';

import './styles/index.scss';
import { Quiz } from 'features/quiz/Quiz';
import { Question } from 'features/question/Question';

header.init();
gallery.init();
rooms.init();
nutrition.init();
cafe.init();
gorgippia.init();
beach.init();
instagram.init();
reviews.init();
hotels.init();
vacancy.init();

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>,
  document.getElementById('root'),
);

const quiz = document.getElementById('quiz');
if (quiz) {
  ReactDOM.render(
    <StrictMode>
      <Provider store={store}>
        <Quiz />
      </Provider>
    </StrictMode>,
    quiz,
  );
}

const question = document.getElementById('question');
if (question) {
  ReactDOM.render(
    <StrictMode>
      <Provider store={store}>
        <Question />
      </Provider>
    </StrictMode>,
    question,
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
